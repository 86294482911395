export var HubspotElectricalUpgrade;
(function (HubspotElectricalUpgrade) {
    HubspotElectricalUpgrade["REQUESTED"] = "requested";
    HubspotElectricalUpgrade["RECEIVED"] = "received";
    HubspotElectricalUpgrade["TRANSMITTED"] = "transmitted";
    HubspotElectricalUpgrade["ACCEPTED"] = "accepted";
    HubspotElectricalUpgrade["DECLINED"] = "declined";
    HubspotElectricalUpgrade["ORDERED"] = "ordered";
    HubspotElectricalUpgrade["NOT_APPLICABLE"] = "not_applicable";
})(HubspotElectricalUpgrade || (HubspotElectricalUpgrade = {}));
export var HubspotPhotoUploadSubFolders;
(function (HubspotPhotoUploadSubFolders) {
    HubspotPhotoUploadSubFolders["BUILDING"] = "1.1_Au\u00DFenansicht_Hauses";
    HubspotPhotoUploadSubFolders["ROOF"] = "1.2_Dach";
    HubspotPhotoUploadSubFolders["ROOF_CLOSE_UP"] = "1.3_Dach_Nahaufnahme";
    HubspotPhotoUploadSubFolders["ELECTRICITY_METER"] = "2.1_Stromz\u00E4hler";
    HubspotPhotoUploadSubFolders["CONNECTION_BOX"] = "2.2_Verteilerkasten";
    HubspotPhotoUploadSubFolders["HOUSE_CONNECTION_BOX"] = "2.3_Hausanschlusskasten";
    HubspotPhotoUploadSubFolders["ELECTRICITY_BILL"] = "2.4_Stromrechnung";
})(HubspotPhotoUploadSubFolders || (HubspotPhotoUploadSubFolders = {}));
export var HubspotImageUploadStatus;
(function (HubspotImageUploadStatus) {
    HubspotImageUploadStatus["IN_VALIDATION"] = "In Pr\u00FCfung";
    HubspotImageUploadStatus["ACCEPTED"] = "Gepr\u00FCft";
    HubspotImageUploadStatus["REJECTED_LOW_RESOLUTION_QUALITY"] = "Abgelehnt";
    HubspotImageUploadStatus["REJECTED_WRONG_PICTURE"] = "rejected_wrong_picture";
    HubspotImageUploadStatus["REJECTED_UNCLEAR_PICTURE"] = "rejected_unclear_picture";
    HubspotImageUploadStatus["MISSING"] = "missing";
})(HubspotImageUploadStatus || (HubspotImageUploadStatus = {}));
export var ImageNoteBody;
(function (ImageNoteBody) {
    ImageNoteBody["MAP_CAPTURE"] = "[IMAGE] Kartenbild";
    ImageNoteBody["PLAN"] = "[IMAGE] Belegplan";
})(ImageNoteBody || (ImageNoteBody = {}));
export var SalesNoteBody;
(function (SalesNoteBody) {
    SalesNoteBody["SIGNED_CONTRACTS"] = "[SALES] Signed Contracts";
    SalesNoteBody["ONSITE_VISIT_DOCS"] = "[SALES] On-Site Visit Doku";
})(SalesNoteBody || (SalesNoteBody = {}));
export var DocumentNames;
(function (DocumentNames) {
    DocumentNames["ClientPhotos"] = "photos";
    DocumentNames["PV_AcceptanceReport"] = "Abnahmeprotokoll";
    DocumentNames["PV_TypeLabel"] = "Typenschild";
    DocumentNames["PV_MicroinverterLayout"] = "Mikrowechselrichter-Belegplan";
    DocumentNames["PV_BillOfMaterial"] = "St\u00FCckliste";
    DocumentNames["PV_TestResult"] = "Pr\u00FCfbefund";
    DocumentNames["PV_Meter"] = "Z\u00E4hlerkasten";
    DocumentNames["PV_JunctionBox"] = "Verteilerkasten";
    DocumentNames["PV_ModuleSetupOnRoof"] = "Modulbelegung-am-Dach";
    DocumentNames["PV_FotoIqGate"] = "Foto-IQ-Gate";
    DocumentNames["PV_InstallationDocument"] = "Installationsdokument";
    DocumentNames["PV_OccupancyPlan"] = "Belegplan";
    DocumentNames["PV_AttachedPhotos"] = "Fotos-Anlage";
    DocumentNames["PV_Meter_MainLineTerminal"] = "ZaehlersteckleisteHauptleitungsklemme";
    DocumentNames["PV_TestReportTemplate"] = "TestReportTemplate";
    DocumentNames["PV_HSI_BillOfMaterial"] = "HSI-BillOfMaterial";
    DocumentNames["PV_TerminalBoxPhoto"] = "FotoAnschlusskasten";
    DocumentNames["PV_InstalledInverterPhoto"] = "Foto-installierter-Wechselrichter";
    DocumentNames["PV_InverterLabelPhoto"] = "Foto-Wechselrichter-Typenschild-Etikett";
    DocumentNames["PV_RegistrationNumberPhoto"] = "Foto-Registrierungsnummer";
    DocumentNames["PV_PotentialausgleichPhoto"] = "Foto-Potentialausgleich";
    DocumentNames["PV_ManualSwitchPhoto"] = "Foto-manueller-Umschalter";
    DocumentNames["PV_Scaffolding"] = "Foto-Ger\u00FCst";
    DocumentNames["ES_AcceptanceReport"] = "\u00DCbernahmeprotokoll-ES";
    DocumentNames["ES_BillOfMaterial"] = "St\u00FCckliste-ES";
    DocumentNames["ES_FotoIqGate"] = "Foto-IQ-Gate-ES";
    DocumentNames["ES_Cabling"] = "Verkabelung-ES";
    DocumentNames["ES_EnergyStorage"] = "Stromspeicher-ES";
    DocumentNames["ES_Meter"] = "Z\u00E4hlerkasten-ES";
    DocumentNames["ES_JunctionBox"] = "Verteilerkasten-ES";
    DocumentNames["ES_TestResult"] = "Pr\u00FCfbefund-ES";
    DocumentNames["ES_AttachedPhotos"] = "Fotos-Anlage-ES";
    DocumentNames["ES_EnergiespeicherFoto"] = "EnergiespeicherFoto-ES";
    DocumentNames["ES_Meter_MainLineTerminal"] = "ZaehlersteckleisteHauptleitungsklemme-ES";
    DocumentNames["ES_TestReportTemplate"] = "TestReportTemplate-ES";
    DocumentNames["ES_HSI_BillOfMaterial"] = "HSI-BillOfMaterial-ES";
    DocumentNames["ES_TypeLabel"] = "Typenschild-ES";
    DocumentNames["ES_PV_AcceptanceReport"] = "Abnahmeprotokoll-ES-PV";
    DocumentNames["ES_PV_BillOfMaterial"] = "St\u00FCckliste-ES-PV";
    DocumentNames["ES_PV_FotoIqGate"] = "Foto-IQ-Gate-ES-PV";
    DocumentNames["ES_PV_Meter"] = "Z\u00E4hlerkasten-ES-PV";
    DocumentNames["ES_PV_JunctionBox"] = "Verteilerkasten-ES-PV";
    DocumentNames["ES_PV_InstallationDocument"] = "Installationsdokument-ES-PV";
    DocumentNames["ES_PV_Meter_MainLineTerminal"] = "ZaehlersteckleisteHauptleitungsklemme-ES-PV";
    DocumentNames["ES_PV_TestReportTemplate"] = "TestReportTemplate-ES-PV";
    DocumentNames["ES_PV_HSI_BillOfMaterial"] = "HSI-BillOfMaterial-ES-PV";
    DocumentNames["ES_PV_TypeLabel"] = "Typenschild-ES-PV";
    DocumentNames["WB_PV_TestResult"] = "Pr\u00FCfbefund-WB-PV";
    DocumentNames["WB_ES_PV_TestResult"] = "Pr\u00FCfbefund-WB-ES-PV";
    DocumentNames["WB_WallboxPhoto"] = "Foto-Wallbox-WB";
})(DocumentNames || (DocumentNames = {}));
export const isEnergyStorageDocumentName = (name) => [
    DocumentNames.ES_AcceptanceReport,
    DocumentNames.ES_BillOfMaterial,
    DocumentNames.ES_FotoIqGate,
    DocumentNames.ES_Cabling,
    DocumentNames.ES_EnergyStorage,
    DocumentNames.ES_Meter,
    DocumentNames.ES_JunctionBox,
    DocumentNames.ES_TestResult,
    DocumentNames.ES_AttachedPhotos,
    DocumentNames.ES_EnergiespeicherFoto,
    DocumentNames.ES_Meter_MainLineTerminal,
    DocumentNames.ES_TestReportTemplate,
    DocumentNames.ES_HSI_BillOfMaterial,
    DocumentNames.ES_TypeLabel,
].includes(name);
export const isESPVDocumentName = (name) => [
    DocumentNames.ES_PV_AcceptanceReport,
    DocumentNames.ES_PV_BillOfMaterial,
    DocumentNames.ES_PV_FotoIqGate,
    DocumentNames.ES_PV_Meter,
    DocumentNames.ES_PV_JunctionBox,
    DocumentNames.ES_PV_InstallationDocument,
    DocumentNames.ES_PV_Meter_MainLineTerminal,
    DocumentNames.ES_PV_TestReportTemplate,
    DocumentNames.ES_PV_HSI_BillOfMaterial,
    DocumentNames.ES_PV_TypeLabel,
].includes(name);
export const isWBDocumentName = (name) => [DocumentNames.WB_PV_TestResult, DocumentNames.WB_ES_PV_TestResult].includes(name);
export const isFileOfDocumentName = (fileName, documentName) => {
    if (isWBDocumentName(documentName) &&
        fileName.includes('-WB') &&
        fileName.startsWith(documentName)) {
        return true;
    }
    if (isESPVDocumentName(documentName) &&
        fileName.includes('-ES-PV') &&
        fileName.startsWith(documentName)) {
        return true;
    }
    if (isEnergyStorageDocumentName(documentName) &&
        fileName.includes('-ES') &&
        fileName.startsWith(documentName)) {
        return true;
    }
    if (!isEnergyStorageDocumentName(documentName) &&
        !fileName.includes('-ES') &&
        fileName.startsWith(documentName)) {
        return true;
    }
    return false;
};
